import React, { Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

const Results = lazy(() => import('./pages/results'));
const ResultsFreeVersion = lazy(() => import('./pages/free-version'));
const NotFound = lazy(() => import('./pages/not-found'));

const App = () => (
  <Router>
    <Suspense fallback={(
      <div className="content-loader-total">
        <img src="/img/loader-big.svg" alt="loader" />
      </div>
  )}>
      <Switch>
        <Route exact path="/free-version/:code" component={ResultsFreeVersion} />
        <Route exact path="/:code" component={Results} />
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Suspense>
  </Router>
);

export default App;
